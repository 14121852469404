import * as Yup from "yup";
import { PASSWORD_REGEX } from "../../constants/global";
import { VALIDATION_MESSAGES } from "../../constants/messages";
import { emailValidator } from "../../validations";

export const LoginSchema = Yup.object({
    email: emailValidator,
    password: Yup
    .string()
    .required(VALIDATION_MESSAGES.PASSWORD_REQUIRED)
    .matches(PASSWORD_REGEX, VALIDATION_MESSAGES.INVALID_LOGIN_PASSWORD)
});