import React from 'react';
import { Formik, Form, FormikProps } from 'formik';

interface CommonFormikComponentProps {
  validationSchema: any;
  initialValues: Record<string, any>;
  onSubmit: (values: Record<string, any>, actions: any) => void;
  children: (formikProps: FormikProps<Record<string, any>>, isSubmitting: boolean, isValid: boolean) => React.ReactNode;
  innerRef?: any;
}

const CommonFormikComponent: React.FC<CommonFormikComponentProps> = ({
  validationSchema,
  initialValues,
  onSubmit,
  children,
  innerRef
}) => {

  return (
    <Formik
      innerRef={innerRef}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const scrollToError = () => {

          const property = Object.keys(formikProps?.errors)?.[0];
          if (Array.isArray(formikProps?.errors?.[property])) {

            const firstError: any = formikProps?.errors?.[property];
            if (firstError[0]) {
              const nextProperty: any = Object.keys(firstError?.[0]);

              const errorFieldElement = document.getElementById(`${property}[0].${nextProperty[0]}`);

              if (errorFieldElement) {

                errorFieldElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest',
                });
              }
            }
          } else {
            const errorFieldElement = document.getElementById(property);

            if (errorFieldElement) {

              errorFieldElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }
          }
        };

        return <Form noValidate onSubmit={formikProps.handleSubmit} className="theme-common-form">
          <>
            {children(formikProps, formikProps.isSubmitting, formikProps.isValid)}
            {formikProps?.submitCount > 0 && Object.keys(formikProps?.errors).length > 0 && scrollToError()}
          </>
        </Form>
      }}
    </Formik>
  );
};

export default CommonFormikComponent;