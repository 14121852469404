import React, { ChangeEvent, useEffect, useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from 'react-router-dom';
import AuthBanner from "../../assets/images/login-banner.jpg";
import Logo from '../../assets/images/logo.svg';
import CommonFormikComponent from '../../components/common/CommonFormikComponent';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import InputPassword from '../../components/common/InputPassword';
import InputText from '../../components/common/InputText';
import Loader from '../../components/common/Loader';
import storage from '../../helpers/storage';
import { loginEmailPassword } from '../../services/auth.service';
import { LoginSchema } from './validation';

/**
 * Form values type definition.
 */
type FormValues = {
    email: string;
    password: string;
}

export default function Login() {
    const AppName = process.env.REACT_APP_NAME;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [captchaCompleted, setCaptchaCompleted] = useState<boolean>(false);
    const reCapthaSiteKey: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    const rememberMe: any = storage.getData('remember-me');
    const rememberMeChecked = rememberMe && rememberMe.isChecked;
    const [isChecked, setIsChecked] = useState(rememberMeChecked || false);


    /**
     * Initial Form Values
     */
    const initialValues: FormValues = {
        email: rememberMe ? rememberMe.email : '',
        password: rememberMe ? rememberMe.password : '',
    }

    useEffect(() => {
        if (storage.getToken() != null) {
            return navigate('/dashboard')
        } else {
            storage.removePermissionAndLabel();
            storage.clearToken();
            storage.clearData('filters');
            storage.clearData('currentModule');
        }
    }, [navigate])

    /**
     * Handles the change event of a checkbox input.
     * @param {ChangeEvent<HTMLInputElement>} event - The change event object.
     * @returns {void}
     */
    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked)
    };

    /**
     * Submit Method to call when user clicks on login button
     * @async
     * @param {FormValues} values
     * @param {any} actions
     * @returns {*}
     */
    const handleSubmit = (values: Record<string, any>, actions: any) => {
        setLoading(true);
        actions.setSubmitting(true);
        const formData: any = {
            email: values.email, password: values.password
        };

        loginEmailPassword(formData).then((response: any) => {
            actions.setSubmitting(false);
            if (isChecked) {
                storage.setData('remember-me', { email: values.email, password: values.password, isChecked });
            } else {
                storage.clearData('remember-me');
            }
            handleLoginResponse(response);
        }).catch((error: any) => {
            setLoading(false);

            actions.setSubmitting(false);
        })
    }

    /**
     * Handles the response after a successful login.
     * @param {Object} responseData - The response data from the login API.
     * @returns {void}
     */
    const handleLoginResponse = (responseData: any) => {
        setLoading(false);
        storage.setToken(responseData?.data.token);
        storage.setRefreshToken(responseData?.data?.refresh_token);
        navigate('/app/dashboard');
    }

    return (
        <React.Fragment>
            <Loader isLoading={loading} />
            <Row className="g-0 vh-100 position-relative z-1">
                <Col md className="h-100 overflow-auto">
                    <Row className="justify-content-center g-0 h-100">
                        <Col lg={12} className="p-3">
                            <div className="custom-max-width-320 m-auto auth-padding-main">
                                <div className="mb-4">
                                    <Link to="/" className="d-inline-block">
                                        <Image
                                            className="img-fluid"
                                            src={Logo}
                                            alt={`${AppName} Logo`}
                                            width={174}
                                            height={60}
                                        />
                                    </Link>
                                </div>
                                <p className="mb-1 opacity-75">Welcome to</p>
                                <h1 className="fw-semibold mb-4 pb-1 fs-2">Admin Panel</h1>

                                <CommonFormikComponent
                                    validationSchema={LoginSchema}
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                >
                                    {(formikProps) => (
                                        <>
                                            <InputText
                                                wrapperClassname="mb-3 pb-1"
                                                controlId="email"
                                                label='Email Address*'
                                                placeholder="john.smith@example.com"
                                                touched={formikProps.touched.email}
                                                handleBlur={formikProps.handleBlur}
                                                handleChange={formikProps.handleChange}
                                                errorsField={formikProps.errors.email}
                                                value={formikProps.values.email}
                                            />

                                            <InputPassword
                                                wrapperClassname="mb-3 pb-1"
                                                controlId="password"
                                                label='Password*'
                                                // placeholder="••••••••"
                                                touched={formikProps.touched.password}
                                                handleBlur={formikProps.handleBlur}
                                                handleChange={formikProps.handleChange}
                                                errorsField={formikProps.errors.password}
                                                value={formikProps.values.password}
                                            />

                                            <Form.Group className="mb-3 pb-1">
                                                <Row xs="auto" className="align-items-center justify-content-between gx-0">
                                                    <Col>
                                                        <CustomCheckbox
                                                            controlId="rememberCheck"
                                                            value="Remember Me"
                                                            label='Remember Me'
                                                            checked={isChecked}
                                                            onChange={onChangeCheckbox}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Link className="fs-14 fw-semibold text-decoration-none" to="/forgot-password">Forgot Password?</Link>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="mb-3 pb-1">
                                                <ReCAPTCHA
                                                    sitekey={reCapthaSiteKey}
                                                    onChange={() => setCaptchaCompleted(true)}
                                                />
                                            </Form.Group>
                                            <Stack direction="horizontal" gap={3} className="justify-content-end">
                                                <Button className="custom-min-width-85" variant="dark" type="submit" disabled={formikProps.isSubmitting || !captchaCompleted || loading}>
                                                    Login
                                                </Button>
                                            </Stack>
                                        </>
                                    )}
                                </CommonFormikComponent>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col
                    md={6}
                    lg={7}
                    xl={8}
                    className="h-100 start-0 top-0 z-n1 order-md-first d-none d-md-block"
                >
                    <Card className="h-100 text-white border-0">
                        <Image
                            className="h-100 object-fit-cover w-100"
                            src={AuthBanner}
                            alt="Login Banner"
                        />
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    )
}
