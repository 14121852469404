import * as yup from "yup";
import { PASSWORD_REGEX } from "../../constants/global";
import { VALIDATION_MESSAGES } from "../../constants/messages";

export const ResetPasswordSchema = yup.object({
    password: yup
    .string()
    .required(VALIDATION_MESSAGES.NEW_PASSWORD_REQUIRED)
    .matches(PASSWORD_REGEX, VALIDATION_MESSAGES.VALID_PASSWORD),
    confirm_new_password: yup.string().oneOf([yup.ref('password'), ''], VALIDATION_MESSAGES.PASSWORD_MUST_MATCH).required(VALIDATION_MESSAGES.CONFIRM_PASSWORD_REQUIRED),
});