import { RouteObject, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";
import { protectedRoutes } from "./protected";
import storage from "../helpers/storage";

/**
 *
 * @returns Routes Elements
 */

export default function AppRoutes() {
	const mainRoutes: RouteObject[] = storage.getToken() != null ? protectedRoutes : [];
	const element = useRoutes([...publicRoutes, ...mainRoutes]);
	return <>{element}</>;
}