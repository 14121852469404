import * as React from 'react';


/**
 * For Importing lazy loading module
 *
 * @export
 * @template T extends React.ComponentType<any>
 * @template I extends { [K2 in K]: T }
 * @template K extends keyof I
 * @param {() => Promise<I>} factory
 * @param {K} name
 * @returns {I}
 */
export function lazyImport<
  T extends React.ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: React.lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
}