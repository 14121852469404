import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap'

interface ILoader {
    isLoading: boolean
}

/**
 * Loader component for showing progress
 * @date 8/28/2023 - 11:23:57 AM
 *
 * @param {{ isLoading: any; }} {
    isLoading
}
 * @returns {*}
 */

const Loader: FC<ILoader> = ({
    isLoading
}) => {
    return (
        <div
            className={`theme-loader-z-index align-items-center bg-black bg-opacity-50 d-flex h-100 justify-content-center position-fixed start-0 top-0 w-100 ${isLoading === true ? 'd-block' : 'd-none'}`}>
            <Spinner animation="border" variant="light" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default Loader;