import { Button, Container, Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import { MdExpandMore } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../../assets/images/logo.svg";
import storage from "../../../../helpers/storage";
import "./header.scss";

export const Header = ({ userFullName, toggleSidebarButton, isActiveSidebar }: any) => {
  const navigate = useNavigate();
  
  const profileHandler = () => {
    navigate("/app/profile");
  }

  const logoutHandler = () => {
    storage.clearToken();
    storage.clearData('filters');
    storage.clearData('currentModule');
    storage.removePermissionAndLabel();
    navigate('/')
  }

  return (
    <Navbar
      bg="white"
      variant="light"
      className="py-0 px-md-1 shadow z-1 theme-top-header"
      data-bs-theme="light"
    >
      <Container fluid className="h-100">
        <Button
          onClick={toggleSidebarButton}
          variant="link"
          className="align-items-center d-flex d-xl-none justify-content-center me-3 navMenuBtn p-0 py-3"
          aria-label="Toggle Sidebar Menu"
        >
          <span
            className={`bg-primary d-inline-block menuTrigger position-relative text-center ${isActiveSidebar ? "active" : ""
              }`}
          ></span>
        </Button>
        <Link to="/app/dashboard" className="me-3">
          <Image
            className="img-fluid"
            src={Logo}
            alt="Logo"
            width={117}
            height={40}
          />
        </Link>
        <Nav className="ms-auto align-items-center order-md-last">
          <Dropdown className="profileDropdown ms-1">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-profile"
              className="border-0 fw-semibold text-decoration-none p-0 text-body"
            >
              <div className="align-middle fs-6 d-none d-md-inline-block px-2 text-truncate custom-max-width-150">
                {userFullName}
              </div>
              <MdExpandMore size={24} className="profile-down-arrow" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="border-0 shadow">
              <Dropdown.Item onClick={profileHandler}>Profile</Dropdown.Item>
              {/* <Dropdown.Item>Reminder</Dropdown.Item> */}
              <Dropdown.Item onClick={logoutHandler}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
