import React, { useCallback, useEffect, useState } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo from "../../../assets/images/logo.svg";
import { purchaseOrderService } from '../../purchase-order-management/services';

const ConfirmPurchaseOrder: React.FC = () => {
    const [displayError, setDisplayError] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const confirmPurchaseOrder = useCallback(async (t: any) => {
        try {
            await purchaseOrderService.confirmPurchaseOrder({ t });

            navigate('/thank-you');
        } catch (error) {
            setDisplayError(true);
        }
    }, [navigate]);

    useEffect(() => {
        if (searchParams.get('t')) {
            confirmPurchaseOrder(searchParams.get('t'));
        }
    }, [confirmPurchaseOrder, searchParams]);

    return (
        <div className='vh-100 bg-body-tertiary overflow-auto d-flex align-items-center'>
            <div className='text-center m-auto p-3'>
                <div className="mb-5 d-inline-block">
                    <Image
                        className="img-fluid"
                        src={Logo}
                        alt="Logo"
                        width={174}
                        height={60}
                    />
                </div>

                {!displayError && <>
                    <div className='mb-3'>
                        <Spinner animation="border" variant="dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    <h1 className="display-6 fw-bold mb-2">Please Wait...</h1>
                </>
                }
                {displayError ?
                    <>
                        <div className='display-3 text-warning mb-3'>
                            <MdWarning />
                        </div>
                        <p>We are facing some issues confirming your purchase order. Please contact the MIXT Team.</p>
                    </>
                    : <p>We are confirming your purchase order.</p>
                }
            </div>
        </div>
    )
}
export default ConfirmPurchaseOrder;