import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { INVALID_PHONE, PHONE_REQUIRED, VALIDATION_MESSAGES } from "../constants/messages";

/**
 * Phone validator using Yup.
 * 
 * @constant
 * @name phoneValidator
 * @type {Yup.StringSchema<string>}
 */
export const phoneValidator = Yup.string().required(PHONE_REQUIRED).transform((value: string) => value.trim()).test('customValidation1', INVALID_PHONE, (value) => isValidPhoneNumber(value))

/**
 * Email validator using Yup.
 * 
 * @constant
 * @name emailValidator
 * @type {Yup.StringSchema<string>}
 */
export const emailValidator = Yup.string().email(VALIDATION_MESSAGES.VALID_EMAIL).matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, VALIDATION_MESSAGES.VALID_EMAIL).transform((value: string) => value.trim()).required(VALIDATION_MESSAGES.EMAIL_REQUIRED)

/**
 * name validator using Yup.
 * 
 * @constant
 * @name nameValidator
 * @type {Yup.StringSchema<string>}
 * 
 * 
 */
export const nameValidator = Yup.string().required(VALIDATION_MESSAGES.NAME_REQUIRED)
    .matches(
        /^(?!\s)(?!.*\s$)(?!.*\s{2})(.{1,255})$/,
        VALIDATION_MESSAGES.INVALID_NAME_FIELDS
    )
/**
 * name validator with non required fiedls using Yup.
 * 
 * @constant
 * @name nameNonReqValidator
 * @type {Yup.StringSchema<string>}
 * 
 * 
 */
export const nameNonReqValidator = Yup.string().matches(
    /^(?!\s)(?!.*\s$)(?!.*\s{2})(.{0,255})$/,
    VALIDATION_MESSAGES.INVALID_NAME_FIELDS
)