export const GLOBAL_ENVIRONMENT = 'global/environment';

export const API_PATH: any = {
    COMMON: {
        BUYER_LIST: 'general/buyer-list',
        COUNTRIES: 'general/countries',
        STATES: 'general/states/',
        SALES_CHANNEL: 'general/sales-channel-list',
        WAREHOUSE_LIST: 'general/warehouse-list',
        WAREHOUSE_PRODUCT_LIST: 'general/warehouse-products/',
        VENDOR_LIST: 'general/vendor-list',
        VENDOR_PRODUCT_LIST: 'general/vendor-product-list/',
        BRAND_LIST: 'general/brand-list',
        PRODUCT_LIST: 'general/product-list',
        PRODUCT_LIST_NEW: 'general/product-list-new',
        CARRIER_LIST: 'general/carrier-list',
    },

    WAREHOUSE: {
        ADD: 'warehouse/add',
        EDIT: 'warehouse/edit/',
        HISTORY: 'warehouse/history-logs/',
        LISTING: 'warehouse/list',
        STATUS: 'warehouse/update-status/',
        VIEW: 'warehouse/detail/'
    },

    AUTH: {
        LOGIN: 'auth/login',
        FORGOT_PASSWORD: 'auth/forgot-password',
        RESET_PASSWORD: 'auth/reset-password',
        REFRESH_TOKENS_API: 'auth/refresh-token',
        VALID_TOKEN: 'auth/validate-reset-token'
    },

    ADMIN: {
        ADMIN_PROFILE: 'user/profile',
        CHANGE_PASSWORD: 'user/change-password',
        UPDATE_PROFILE: 'user/update-profile'
    },

    BRAND: {
        LISTING: 'brand/list',
        ADD: 'brand/add',
        EDIT: 'brand/edit/',
        DELETE: 'brand/delete/',
        DETAIL: 'brand/detail/',
        STATUS: 'brand/edit-status/',
        RESTORE: 'brand/restore/'
    },

    SHIPPING_TERM: {
        LISTING: 'shipping-terms/list',
        ADD: 'shipping-terms/add',
        EDIT: 'shipping-terms/edit/',
        DELETE: 'shipping-terms/delete/',
        DETAIL: 'shipping-terms/detail/',
        STATUS: 'shipping-terms/edit-status/',
        RESTORE: 'shipping-terms/restore/'
    },

    PAYMENT_TERM: {
        LISTING: 'payment-terms/list',
        ADD: 'payment-terms/add',
        EDIT: 'payment-terms/edit/',
        DELETE: 'payment-terms/delete/',
        DETAIL: 'payment-terms/detail/',
        STATUS: 'payment-terms/edit-status/',
        RESTORE: 'payment-terms/restore/'
    },

    ADMIN_USERS: {
        LISTING: 'user/admin-list',
        ADD: 'user/create',
        EDIT: 'user/edit-admin/',
        DELETE: 'user/delete/',
        DETAIL: 'user/detail/',
        STATUS: 'user/edit-status/',
        RESTORE: 'user/restore/',
        RESET_PASSWORD: 'user/reset-admin-password/',
        ROLES_LIST: 'user/role-list'
    },

    ROLES: {
        PERMISSION_LIST: 'general/permission-list',
        ADD: 'role-and-right/add',
        EDIT: 'role-and-right/edit/',
        DELETE: 'role-and-right/delete/',
        DETAIL: 'role-and-right/detail/',
        LISTING: 'role-and-right/list'
    },

    SALESCHANNEL: {
        // ADDSHOPIFY: 'sales-channel/shopify',
        ADDSHOPIFY: 'sales-channel/add-shopify-store',
        ADDAMAZON: 'sales-channel/add-amazon-store',
        ADDWALMART: 'sales-channel/add-walmart-store',
        AMAZONINTEGRASTION: 'sales-channel/amazon-integration',
        AMAZONCALLBACK: 'sales-channel/amazon-callback',
        CHECKSHOPIFYSALESCHANNELEXIST: 'sales-channel/shopify/store-exist',
        EDIT: 'sales-channel/edit/',
        HISTORY: 'sales-channel/history-logs/',
        INSTALLATIONSHOPIFY: 'sales-channel/shopify-callback',
        LISTING: 'sales-channel/list',
        STATUS: 'sales-channel/update-status/'
    },

    SHIPPINGPROVIDER: {
        LISTING: 'shipping-provider/list',
        SYNC_CARRIER_CONNECTED: 'shipping-provider/sync-carrier-connected',
    },

    VENDOR: {
        ADD: 'vendor/add',
        EDIT: 'vendor/edit/',
        HISTORY: 'vendor/history-logs/',
        LISTING: 'vendor/list',
        STATUS: 'vendor/edit-status/',
        VIEW: 'vendor/detail/',
        PAYMENT_TERMS: 'vendor/payment-term-list',
        SHIPPING_TERMS: 'vendor/shipping-term-list'
    },

    ORDER: {
        LISTING: 'order/list',
        ADD_MANUAL_ORDER: 'order/add-manual-order',
        EDIT: 'order/edit/',
        ASSIGN_USER: 'order/assign-user',
        DETAIL: 'order/detail/',
        ON_HOLD: 'order/hold-unhold',
        EXPORT: 'order/export',
        NOTES_UPDATE: '/order/notes/',
        PACKING_SLIP: 'order/print-packing-slip',
        PICK_LIST: 'order/print-pick-list',
        EDIT_RECIEPIENT: 'order/edit-recipient/',
        HISTORY: 'order/history-logs/',
        ESTIMATE_SHIP_RATE: 'order/shipping-rates-estimate',
        ADDITIONAL_SHIP_RATE: 'order/shipping-rates-estimate-additional',
        SAVE_SHIPPING_RATE_PROVIDER: 'order/shipping-rates/',
        SHIP: 'order/ship-orders',
        CANCEL: 'order/cancel',
        MISSING_ORDER: 'order/update-missing-product',
        UPDATE_DIMENSIONS: 'order/update-dimension/',
        RESTORE: 'order/restore-cancel',
        MARK_VALID_ADDRESS: 'order/mark-recipient-address-valid/',
        CREATE_DUPLICATE_ORDER: 'order/duplicate-order',
    },

    PRODUCT: {
        ADD: 'product/add',
        EDIT: 'product/edit/',
        HISTORY: 'product/history-logs/',
        LISTING: 'product/list',
        STATUS: 'product/edit-status/',
        VIEW: 'product/detail/',
        DELETE: 'product/delete/',
        RESTORE: 'product/restore/',
        VENDOR_LIST: 'product/vendor-list',
        CORE_PRODUCT_LIST: 'product/list-for-bundle',
        SALES_CHANNEL_LIST: 'product/sales-channel-list',
        BUYER_LIST: 'product/buyer-list',
        BRAND_LIST: 'product/brand-list',
        IMAGE_UPLOAD: 'product/upload-image',
        IMAGE_UPLOAD_MULTIPLE: 'product/upload-multiple-image',
        DELETE_IMAGE: 'product/delete-image',
        UPDATE_STATUS_SALES: 'product/active-list/edit-status/',
        UPDATE_STATUS_VENDOR: 'product/vendor-list/edit-status/',
        EXPORT: 'product/export'
    },

    PURCHASE_ORDER: {
        ADD: 'purchase-orders/add',
        EDIT: 'purchase-orders/edit/',
        LISTING: 'purchase-orders/list',
        SHIPPING_TERMS_LIST: 'vendor/shipping-term-list',
        PAYMENT_TERMS_LIST: 'vendor/payment-term-list',
        VENDOR_LIST: 'purchase-orders/vendor-list',
        AUTHORIZE_PO: 'purchase-orders/authorize-po',
        CONFIRM_PURCHASE_ORDER: 'purchase-orders/confirm-purchase-order',
        MANUAL_CONFIRM: 'purchase-orders/manual-confirm',
        RESEND_PO: 'purchase-orders/resend-po',
        RE_AUTHORIZE_PO: 'purchase-orders/re-authorize-po',
        DETAIL: 'purchase-orders/detail/',
        RECEIVE_PO: 'purchase-orders/receive/',
        HISTORY: 'purchase-orders/history-logs/',
        VOID: 'purchase-orders/void',
        EDIT_NOTES: 'purchase-orders/edit-internal-notes/',
        EXPORT: 'purchase-orders/export'
    },

    INVENTORY: {
        DETAIL: 'inventory/detail-new/',
        DETAILOLD: 'inventory/detail/',
        LISTING: 'inventory/list',
        IN_TRANSIT_IN: 'inventory/in-transit-list/',
        HISTORY_LOG: 'inventory/history-logs/',
        ALLOCATED_DETAIL: 'inventory/allocated-list/',
        ADD_INVENTORY_STOCK: 'inventory/add-stock',
        REMOVE_INVENTORY_STOCK: 'inventory/deduct-stock/',
        PRODUCT_HISTORY_LOG: 'inventory/product-history-logs',
        AVAILABILITY_DETAIL: 'inventory/warehouse-availability-list/'
    },
    BRAND_REPORTING: {
        LISTING: 'brand-reporting/list',
        EXPORT: 'brand-reporting/export'
    },
    INVENTORY_VALUE: {
        LISTING: 'inventory-reporting/list',
        EXPORT: 'inventory-reporting/export'
    },
    PROFATIBILITY_REPORT: {
        LISTING: 'profitability-report/list',
        EXPORT: 'profitability-report/export'
    },
    ORDERBOTS: {
        BOTS_GET_API: 'order-bot/list-filter-types-operator-actions',
        DELETE: 'order-bot/delete/',
        HISTORY: 'order-bot/history-log/',
        LISTING: 'order-bot/list',
        STATUS: 'order-bot/edit-status/',
        ADD: 'order-bot/add',
        DETAIL: 'order-bot/detail/',
        EDIT: 'order-bot/edit',
    },

    SHIPMENT: {
        LISTING: 'order/shipment/list',
        EXPORT: 'order/shipment/export',
        PACKING_SLIP: 'shipment/print-packing-slip',
        PICK_LIST: 'shipment/print-pick-list',
        VOID: 'order/shipment/void',
        BATCH_NO: 'order/shipment/batch/',
    },
}


export const ENQUIRY = 'enquiry'