import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FC, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import AppTooltip from './tooltip';
interface IThemeInputPassword {
    controlId?: string;
    label?: string;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    inputClassName?: string;
    disabled?: any;
    readOnly?: boolean;
    wrapperClassname?: any;
}

/**
 * Input Type Password Reusable Component
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; inputClassName: any; disabled: any; readOnly: any; wrapperClassname: any; }} param0
 * @param {*} param0.controlId
 * @param {*} param0.label
 * @param {*} param0.placeholder
 * @param {*} param0.handleChange
 * @param {*} param0.handleBlur
 * @param {*} param0.errorsField
 * @param {*} param0.touched
 * @param {*} param0.value
 * @param {*} param0.inputClassName
 * @param {*} param0.disabled
 * @param {*} param0.readOnly
 * @param {*} param0.wrapperClassname
 * @returns
 */

const ThemeInputPassword: FC<IThemeInputPassword> = ({
    controlId,
    label,
    placeholder,
    handleChange,
    handleBlur,
    errorsField,
    touched,
    value,
    inputClassName,
    disabled,
    readOnly,
    wrapperClassname
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => setShowPassword((show) => !show);

    return (
        <Form.Group className={wrapperClassname}>
            <Form.Label htmlFor={controlId} className={`mb-1 ${inputClassName === 'theme-inputtext-lg' ? '' : 'small'}`}>{label}</Form.Label>
            <div className='position-relative w-100 p-input-icon-right'>
                <InputText
                    id={controlId}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange(controlId)}
                    className={errorsField && touched ? `p-invalid is-invalid w-100 ${inputClassName ? inputClassName : ''}` : `w-100 ${inputClassName ? inputClassName : ''}`}
                    onBlur={handleBlur(controlId)}
                    disabled={disabled}
                    type={showPassword ? 'text' : 'password'}
                    readOnly={readOnly}
                />
                <AppTooltip title={showPassword ? "Hide Password" : "Show Password"} placement="top">
                    <Button
                        severity='secondary'
                        type="button"
                        className="position-absolute top-50 end-0 translate-middle-y px-2 h-100"
                        icon={showPassword ? <MdOutlineVisibilityOff size={18} /> : <MdOutlineVisibility size={18} />}
                        onClick={togglePasswordVisibility}
                        aria-label={showPassword ? "Hide Password" : "Show Password"}
                        text
                    />
                </AppTooltip>
            </div>
            <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
                {errorsField}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default ThemeInputPassword