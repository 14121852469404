import { useEffect, useRef, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { NavItems } from "./navItems";
import './sidebar.scss';

type UserProfile = {
    id: number;
    email: string;
    name: string;
    job_title: string;
    role_id: number;
    label: string;
    status: number;
    permissions?: { section_alias: string }[];
};

const Sidebar = ({ isActiveSidebar, toggleSidebarButton, navItems }: any) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState<number | null>(null);
    const [filteredNavItems, setFilteredNavItems] = useState([]);
    const sidebarRef = useRef<any>(null);

    const handleSubmenu = (idx: any) => {
        if (isSubMenuOpen === idx) {
            setIsSubMenuOpen(null);
        } else {
            setIsSubMenuOpen(idx);
        }

    };

    const handleToggleSidebar = () => {
        if (isSubMenuOpen !== null && isActiveSidebar === false) {
            setIsSubMenuOpen(null);
        }
        toggleSidebarButton();
    }

    useEffect(() => {
            filterNavItems(navItems);
    }, [navItems]);

    const handleNavLinkClick = () => {
        if (isActiveSidebar) {
            setIsSubMenuOpen(null);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isActiveSidebar && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
                setIsSubMenuOpen(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isActiveSidebar]);

    const filterNavItems = async (userProfile: UserProfile) => {
        let userPermissions: any = userProfile?.permissions?.map((role: any) => role?.section_alias) || [];
        
        userPermissions.push('DASHBOARD');

        const filteredItems: any = NavItems.filter((item: any) => {
            if (!item.items) {
                return userPermissions.includes(item?.menuAlias) || userProfile?.label === "SUPER_ADMIN";
            } else {
                const filteredSubItems = item.items.filter((subItem: any) =>
                    userPermissions.includes(subItem?.menuAlias) || userProfile?.label === "SUPER_ADMIN"
                );

                return filteredSubItems.length > 0;
            }
        }).map((item: any) => {
            if (item.items) {
                const filteredSubItems = item.items.filter((subItem: any) =>
                    userPermissions.includes(subItem?.menuAlias) || userProfile?.label === "SUPER_ADMIN"
                );

                return {
                    ...item,
                    items: filteredSubItems
                };
            }
            return item;
        });

        setFilteredNavItems(filteredItems);
    };

    return (
        <div ref={sidebarRef} className={`sidebarMenu ${isActiveSidebar ? 'sidebarAction' : ''}`}>
            <Button variant="link" onClick={handleToggleSidebar} className="align-items-center bg-white border border-primary text-primary d-xl-flex h-20 justify-content-center mb-3 p-0 position-absolute rounded-circle start-100 bottom-0 translate-middle-x w-20 z-3 toogle-button d-none" aria-label="Toggle Sidebar Menu">
                {isActiveSidebar ? <IoIosArrowForward size={12} /> : <IoIosArrowBack size={12} />}
            </Button>
            <Navbar role="navigation" bg="primary" variant="dark" expand="xxl" className="w-100 h-100 p-0">
                <div className="d-flex flex-column w-100 h-100">
                    <div className="overflow-x-hidden overflow-y-auto sidebarList">
                        <Nav defaultActiveKey="/app/dashboard" as="ul" className="flex-column p-2">
                            {
                                filteredNavItems?.map((elem: any) => {
                                    const { id, menuName, menuIcon, path, items, disabled } = elem;
                                    return (
                                        <Nav.Item as="li" key={id}>
                                            {!items && (
                                                <Nav.Link onClick={handleNavLinkClick} as={NavLink} to={path} className={`align-items-center d-flex px-0 sidebarLink text-nowrap rounded-1 ${disabled ? 'disabled' : ''}`}>
                                                    <span className="py-1 text-center min-w-44 sidebarIcon">{menuIcon}</span>
                                                    <span className="hideInSmallSidebar">{menuName}</span>
                                                </Nav.Link>
                                            )}
                                            {items && (
                                                <Nav.Link key={id} as={Button} variant="link" onClick={() => handleSubmenu(id)} className={`align-items-center d-flex px-0 sidebarLink text-nowrap rounded-1 w-100 text-white ${disabled ? 'disabled' : ''}`}>
                                                    <span className="py-1 text-center min-w-44 sidebarIcon">{menuIcon}</span>
                                                    <span className="hideInSmallSidebar text-wrap text-start lh-sm">{menuName}</span>
                                                    <span className="ms-auto sub-menu-arrow">
                                                        {isSubMenuOpen === id ? <MdOutlineKeyboardArrowDown size={18} /> : <MdOutlineKeyboardArrowRight size={18} />}
                                                    </span>
                                                </Nav.Link>
                                            )}
                                            <Nav as="ul" className={`flex-column p-0 ${isSubMenuOpen === id && items ? '' : 'd-none'}`}>
                                                {
                                                    items?.map((subItems: any) => {
                                                        const { id, menuName, menuIcon, path, disabled } = subItems;
                                                        return (
                                                            <Nav.Item as="li" key={id}>
                                                                <Nav.Link as={NavLink} to={path} className={`align-items-center d-flex px-0 sidebarLink text-nowrap rounded-1 ${disabled ? 'disabled' : ''}`}>
                                                                    <span className="py-1 text-center min-w-44 sidebarIcon d-none ps-1">{menuIcon}</span>
                                                                    <span className="hideInSmallSidebar">{menuName}</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        )
                                                    })
                                                }
                                            </Nav>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                    </div>
                </div>
            </Navbar>
        </div >
    );
};

export default Sidebar;