import React from 'react';
import { Image } from 'react-bootstrap';
import { MdCheckCircle } from 'react-icons/md';
import Logo from "../../../assets/images/logo.svg";

const ThankYou: React.FC = () => {
	
	return (
		<div className='vh-100 bg-body-tertiary overflow-auto d-flex align-items-center'>
			<div className='text-center m-auto p-3'>
				<div className="mb-5 d-inline-block">
					<Image
						className="img-fluid"
						src={Logo}
						alt="Logo"
						width={174}
						height={60}
					/>
				</div>
				
				<div className='display-3 text-success mb-3'>
					<MdCheckCircle />
				</div>
				<h1 className="display-6 fw-bold mb-2">Thank You!</h1>
				<p>Thank you for confirming the purchase order.</p>
			</div>
		</div>
	)
}
export default ThankYou;