import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import storage from '../../helpers/storage';

export default function PageNotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    if (storage.getToken() == null) {
      return navigate('/login')
    } else {
      return navigate('/app/dashboard')
    }
  }, [navigate]);

  return (<></>);
}
