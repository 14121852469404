import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Header } from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { getAdminProfile } from "../../../services/admin.service";
import storage from "../../../helpers/storage";

const MainLayout = () => {
    const [isActiveSidebar, setIsActiveSidebar] = useState(false);
    const [userName, setUserName] = useState('');
    const [navItems, setNavItems] = useState([]);

    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async () => {
        try {
            const response = await getAdminProfile();
            setUserName(response?.data?.name);
            setNavItems(response?.data);
            storage.setData('label', response?.data?.label);
            storage.setData('role_id', response?.data?.role_id)
            const permissionsData = response?.data?.permissions?.map((role: any) => role);
            
            if (permissionsData) {
                let permissions = permissionsData
                    .flat()
                    .filter((permission: any) => permission.status === 1)
                    .map((permission: any) => permission.name);
                storage.setData('permissions', permissions);
            }

        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    }

    const toggleSidebarButton = () => {
        setIsActiveSidebar((current) => !current);
    };

    return (
        <main className="mainContentBox vh-100">
            {isActiveSidebar && (
                <div
                    onClick={toggleSidebarButton}
                    className="backdrop bg-black bg-opacity-25 bottom-0 position-fixed start-0 w-100"
                ></div>
            )}
            <div className="d-flex flex-column h-100 w-100">
                <Header
                    toggleSidebarButton={toggleSidebarButton}
                    isActiveSidebar={isActiveSidebar}
                    userFullName={userName}
                />
                <div className="flex-grow-1 overflow-hidden w-100">
                    <Row className="gx-0 h-100">
                        <Col xs="auto" className="h-100">
                            <Sidebar
                                isActiveSidebar={isActiveSidebar}
                                toggleSidebarButton={toggleSidebarButton}
                                navItems={navItems}
                            />
                        </Col>
                        <Col xs className="d-flex flex-column mw-1 h-100 bg-body-tertiary">
                            <Outlet context={[userName, setUserName]} />
                        </Col>
                    </Row>
                </div>
            </div>
        </main>
    );
};

export default MainLayout;
