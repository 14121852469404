const constants = {
	datetimeFormats: {
		date: 'MM/DD/YYYY',
		dateTime: 'MM/DD/YYYY hh:mm A',
		hyphenSeparatedDateTime: "MM-DD-YYYY | hh:mm A"
	},
	dateFormats: {
		slashSeparatedDate: "DD/MM/YYYY",
		hyphenSeparatedDate: "MM-DD-YYYY",
		databaseFormat: "YYYY-MM-DD",
	},
	timeFormats: {
		timeIn24HoursFormat: "HH:mm:ss",
		timeIn12HoursFormat: "hh:mm A",
	},
	channelOptions: [
		{ label: 'All Channels', value: 'all' },
		{ label: 'Amazon.com', value: 'AMAZON.COM', disabled: false },
		{ label: 'Amazon.ca', value: 'AMAZON.CA', disabled: false },
		{ label: 'Shopify', value: 'SHOPIFY', disabled: false },
		{ label: 'Walmart', value: 'WALMART', disabled: false }
	],
	statusOptions: [
		{ label: 'All Status', value: 3 },
		{ label: 'Active', value: 1 },
		{ label: 'Inactive', value: 2 }
	],
	typeOptions: [
		{ label: 'Physical', value: 'physical' },
		{ label: 'Virtual', value: 'virtual' }
	],
	productTypeOptions: [
		{ label: 'All Types', value: 'All' },
		{ label: 'Core', value: 'Core' },
		{ label: 'Bundle', value: 'Bundle' },
	],
	createProductOptions: [
		{ label: 'Core Product', value: 'Core' },
		{ label: 'Bundled/Kit', value: 'Bundle' }
	],
	shipmentOptions: [
        { label: 'Awaiting Shipment', value: 1 },
        { label: 'Unresolved', value: 2 },
        { label: 'On Hold', value: 3 },
        { label: 'Shipped', value: 4 },
        { label: 'Cancelled', value: 5 },
        { label: 'Voided', value: 7 },
    ],
	unresolvedShipmentOptions: [
		{ label: 'All Status', value: 'all' },
        { label: 'Out of Stock', value: 2 },
        { label: 'Product Info Missing', value: 3 },
        { label: 'Order Info Missing', value: 4 },
    ],
	shippedShipmentOptions: [
		{ label: 'All Status', value: 'all' },
        { label: 'Awaiting Delivery', value: 1 },
        { label: 'Delivered', value: 2 },
        { label: 'Voided', value: 3 }
    ],
	assignedToOptions: [
        { label: 'Assigned to', value: 'Assigned to' },
        { label: 'Assigned 1', value: 'Assigned 1' },
        { label: 'Assigned 2', value: 'Assigned 2' },
    ],
	// salesChannelOptions: [
    //     { label: 'All Sales Channel', value: 'all' },
    //     { label: 'Sales Channel 1', value: 'Sales Channel 1' },
    //     { label: 'Sales Channel 2', value: 'Sales Channel 2' },
    // ],
    warehousesOptions: [
        { label: 'All Warehouses', value: 'all' },
        { label: 'Warehouses 1', value: 'Warehouses 1' },
        { label: 'Warehouses 2', value: 'Warehouses 2' },
    ],
	purchaseOrderStatus: [
		{ label: 'Awaiting Authorization', value: 1, name: 'AWAITING_AUTHORIZATION' },
		{ label: 'Awaiting Vendor Confirmation', value: 2, name: 'AWAITING_VENDOR_CONFIRMATION' },
		{ label: 'Awaiting Re-Authorization', value: 3, name: 'AWAITING_RE_AUTHORIZATION' },
		{ label: 'Pending Delivery', value: 4, name: 'PENDING_DELIVERY' },
		{ label: 'Partially Delivered', value: 5, name: 'PARTIAL_DELIVERY' },
		{ label: 'Fulfilled', value: 6, name: 'FULFILLED' },
		{ label: 'Closed Short', value: 7, name: 'CLOSE_SHORT' },
		{ label: 'Voided', value: 8, name: 'VOIDED' }
	],
	purchaseOrderActions: [
		{ name: 'Authorize PO', value: 'AUTHORIZE_PO' },
		{ name: 'Re-Authorize PO', value: 'RE_AUTHORIZE_PO' },
		{ name: 'Confirm', value: 'CONFIRM' },
		{ name: 'Receive', value: 'RECEIVE' },
		{ name: 'Resend', value: 'RESEND' },
		{ name: 'Print Proforma', value: 'PRINT_PROFORMA' },
		{ name: 'Print Pending Items', value: 'PRINT_PENDING_ITEMS' },
		{ name: 'Export PO', value: 'EXPORT_PO' },
		{ name: 'Void PO', value: 'VOID_PO' },
	],
	discountTypes: [
		{ label: '$', value: 'price' },
		{ label: '%', value: 'percentage' }
	],
	PO_TYPES_OPTIONS: [
		{ label: 'Each', value: 'Each' },
		{ label: 'Master', value: 'Master' },
	],
	inventoryStatusOptions: [
		{ label: 'All Status', value: 3 },
		{ label: 'Satisfactory Stock', value: 1 },
		{ label: 'Out of Stock', value: 2 }
	],
	orderActions: [
		{ name: 'Print Pick List', value: 'PRINT_PICK_LIST' },
		{ name: 'Print Packing Slip', value: 'PRINT_PACKING_SLIP' },
		{ name: 'Export Orders', value: 'EXPORT_ORDERS' },
		{ name: 'Ship Orders', value: 'SHIP_ORDERS' },
		{ name: 'Cancel Orders', value: 'CANCEL' },
		{ name: 'Shipment Label', value: 'DOWNLOAD_LABEL' },
	],
	shipmentActions: [
		{ name: 'Print Pick List', value: 'PRINT_PICK_LIST' },
		{ name: 'Print Packing Slip', value: 'PRINT_PACKING_SLIP' },
		{ name: 'Export Shipment', value: 'EXPORT_SHIPMENTS' },
		{ name: 'Void Shipment', value: 'VOID_SHIPMENT' },
		{ name: 'Shipment Label', value: 'DOWNLOAD_LABEL' },
	],
	shipmentStatusOptions: [
		{ label: 'All Status', value: 3 },
		{ label: 'Shipped', value: 4 },
		{ label: 'Voided', value: 7 }
	],
};

export default constants;