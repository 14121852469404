import React, { useEffect, useState } from 'react';
import { Image, Stack } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import leptopAmazon from "../../../../assets/images/leptop-amazon-com.png";
import leptopMixt from "../../../../assets/images/leptop-mixt.png";
import leptopShopify from "../../../../assets/images/leptop-shopify.png";
import SvgIcons from '../../../../components/common/SvgIcons';
import Logo from "../../../../assets/images/logo.svg";
import { salesChannelService } from '../services';
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";

const SalesChannelCallback: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [channelName, setChannelName] = useState<string>('');

    const addShopifyPlateform = async (shopifyDetails: any) => {
        const shopify = await salesChannelService.installationShopifySalesChannel(shopifyDetails);

        if (shopify?.statusCode === 200) {
            localStorage.removeItem('installationUrl');
            localStorage.removeItem('reinstallationUrl');
            window.close();
        }
    }

    const addAmazonPlateform = async (amazonDetails: any) => {
        try {
            const amazon = await salesChannelService.amazonCallback(amazonDetails);

            if (amazon?.statusCode === 200) {
                localStorage.removeItem('installationUrl');
                window.close();
            }
        } catch (error) {

        }
    }

    const sessionTokenData = async () => {
        console.log('In this function');
            const host: string = searchParams?.get('host') || '';

            console.log('Host: ======>', host);

            const app = createApp({
                apiKey: "6019de515a0bd0287e4707aa2459d8f0", // API key from the Partner Dashboard
                host: host, // host from URL search parameter
                forceRedirect: true
            });
    
            console.log('App: =====>', app);
    
            getSessionToken(app).then((sessionToken: any) => {
                console.log('Session token: =====>', sessionToken);

                const shopifyDetails = {
                    code: searchParams.get('code') || '',
                    shop: searchParams.get('shop'),
                    hmac: searchParams.get('hmac'),
                    state: searchParams.get('state') || '',
                    timestamp: searchParams.get('timestamp'),
                    host: searchParams.get('host'),
                    session_token: sessionToken
                }

                addShopifyPlateform(shopifyDetails);
            });
    }

    useEffect(() => {
        console.log('Search Params: ', searchParams);
        if (searchParams.get('selling_partner_id')) {
            setChannelName('Amazon');
            const amazonDetails = {
                spapi_oauth_code: searchParams.get('spapi_oauth_code'),
                state: searchParams.get('state'),
                selling_partner_id: searchParams.get('selling_partner_id')
            };

            addAmazonPlateform(amazonDetails);
        } else {
            setChannelName('Shopify');

            if(searchParams.get('custom')) {
                const shopifyDetails = {
                    code: searchParams.get('code') || '',
                    shop: searchParams.get('shop'),
                    hmac: searchParams.get('hmac'),
                    state: searchParams.get('state') || '',
                    timestamp: searchParams.get('timestamp'),
                    host: searchParams.get('host'),
                    // session_token: sessionToken
                }

                addShopifyPlateform(shopifyDetails);
            } else {
                sessionTokenData();
            }
        }

    }, [searchParams, sessionTokenData]);

    return (
        <div className='vh-100 bg-body-tertiary overflow-auto d-flex align-items-center'>
            <div className='text-center m-auto p-3'>
                <div className="mb-5">
                    <Image
                        fluid
                        className="pe-none"
                        src={Logo}
                        alt="Logo"
                        width={174}
                        height={60}
                    />
                </div>
                <h4 className='fw-semibold custom-font-size-24'>Authorization is in under process...</h4>
                <h6>Please do not close or refresh the browser.</h6>
                <Stack direction='horizontal' gap={3} className="pt-4 mt-3 pe-none">
                    <Image
                        fluid
                        src={channelName === 'Shopify' ? leptopShopify : leptopAmazon}
                        alt={`Authorization is in under process for ${channelName}`}
                        className="user-select-none"
                        width={150}
                        height={150}
                    />
                    <span className='mx-1'>
                        {SvgIcons.transferIcon}
                    </span>
                    <Image
                        fluid
                        src={leptopMixt}
                        alt={`Authorization is in under process with ${channelName} to Mixt`}
                        className="user-select-none"
                        width={150}
                        height={150}
                    />
                </Stack>
            </div>
        </div>
    )
}
export default SalesChannelCallback;
