// Default Imports
import React, { useEffect, useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

// Common Components
import CommonFormikComponent from '../../components/common/CommonFormikComponent';
import InputPassword from '../../components/common/InputPassword';
import Loader from '../../components/common/Loader';

// Helpers
import storage from '../../helpers/storage';

// Images
import AuthBanner from "../../assets/images/reset-password-banner.jpg";
import Logo from '../../assets/images/logo.svg';

// Services
import { resetPassword } from '../../services/auth.service';

// Validations
import { ResetPasswordSchema } from './validation';
import { validateToken } from '../../services/admin.service';

/**
 * Form values type definition.
 */
type FormValues = {
    password: string;
    confirm_new_password: string;
}

export default function ForgotPassword() {
    const AppName = process.env.REACT_APP_NAME;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [captchaCompleted, setCaptchaCompleted] = useState<boolean>(false);
    const reCapthaSiteKey: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY


    /**
     * Initial Form Values
     */
    const initialValues: FormValues = {
        password: '',
        confirm_new_password: '',
    }

    useEffect(() => {
        const tokenParam = searchParams.get('t');
        if (tokenParam) {
            validToken(tokenParam);
            setToken(tokenParam);
        } else {
            // invalid link when no token in the URL.
            navigate('/login');
        }
    }, [navigate, searchParams]);

    const validToken = async (token: any) => {
        setLoading(true);
        try {
            let payload  = {
                token: token
            }
            await validateToken(payload);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
        }
    }



    /**
     * Submit Method to call when user clicks on login button
     * @async
     * @param {FormValues} values
     * @param {any} actions
     * @returns {*}
     */
    const handleSubmit = (values: Record<string, any>, actions: any) => {
        setLoading(true);
        actions.setSubmitting(true);
        const formData: any = { token: token, password: values.password }
        resetPassword(formData).then((response: any) => {
            setLoading(false);
            actions.setSubmitting(false);
            storage.clearToken();
            storage.clearData('filters');
            storage.clearData('currentModule');
            navigate('/login');
        }).catch((error: any) => {
            setLoading(false);
            actions.setSubmitting(false);
        })
    }

    return (
        <React.Fragment>
            <Loader isLoading={loading} />
            <Row className="g-0 vh-100 position-relative z-1">
                <Col md className="h-100 overflow-auto">
                    <Row className="justify-content-center g-0 h-100">
                        <Col lg={12} className="p-3">
                            <div className="custom-max-width-320 m-auto auth-padding-main">
                                <div className="mb-5">
                                    <Link to="/" className="d-inline-block">
                                        <Image
                                            className="img-fluid"
                                            src={Logo}
                                            alt={`${AppName} Logo`}
                                            width={174}
                                            height={60}
                                        />
                                    </Link>
                                </div>
                                <h1 className="fw-semibold fs-2 mb-0">Reset Password</h1>
                                <p className="mb-4 pb-1 opacity-75">Please enter new password.</p>

                                <CommonFormikComponent
                                    validationSchema={ResetPasswordSchema}
                                    initialValues={initialValues}
                                    onSubmit={(values, actions) => handleSubmit(values, actions)}
                                >
                                    {(formikProps) => (
                                        <>
                                            <InputPassword
                                                wrapperClassname="mb-3 pb-1"
                                                controlId="password"
                                                label='New Password*'
                                                touched={formikProps.touched.password}
                                                handleBlur={formikProps.handleBlur}
                                                handleChange={formikProps.handleChange}
                                                errorsField={formikProps.errors.password}
                                                value={formikProps.values.password}
                                            />

                                            <InputPassword
                                                wrapperClassname="mb-3 pb-1"
                                                controlId="confirm_new_password"
                                                label='Confirm New Password*'
                                                touched={formikProps.touched.confirm_new_password}
                                                handleBlur={formikProps.handleBlur}
                                                handleChange={formikProps.handleChange}
                                                errorsField={formikProps.errors.confirm_new_password}
                                                value={formikProps.values.confirm_new_password}
                                            />

                                            <Form.Group className="mb-3 pb-1">
                                                <ReCAPTCHA
                                                    sitekey={reCapthaSiteKey}
                                                    onChange={() => setCaptchaCompleted(true)}
                                                />
                                            </Form.Group>
                                            <Stack direction="horizontal" gap={3} className="justify-content-end">
                                                <Button className="custom-min-width-85" variant="dark" type="submit" disabled={formikProps.isSubmitting || !captchaCompleted || loading}>
                                                    Submit
                                                </Button>
                                            </Stack>
                                        </>


                                    )}
                                </CommonFormikComponent>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col
                    md={6}
                    lg={7}
                    xl={8}
                    className="h-100 start-0 top-0 z-n1 order-md-first d-none d-md-block"
                >
                    <Card className="h-100 text-white border-0">
                        <Image
                            className="h-100 object-fit-cover w-100"
                            src={AuthBanner}
                            alt="Reset Banner"
                        />
                    </Card>

                </Col>
            </Row >
        </React.Fragment >
    )
}
