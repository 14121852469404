
import { API_PATH } from '../constants/api-path';
import httpClient from './interceptor';

/**
 * @param {email: string, password:string} formData
 * @returns Promise<any>
 */
export const loginEmailPassword = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.AUTH.LOGIN, formData);
};

/**
 * @param {email: string} formData
 * @returns Promise<any>
 */
export const forgotPassword = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.AUTH.FORGOT_PASSWORD, formData);
};

/**
 * @param {token: string, password: string } formData
 * @returns Promise<any>
 */
export const resetPassword = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.AUTH.RESET_PASSWORD, formData);
};


/**
 * @param {refreshToken: string} formData
 * @returns Promise<any>
 */
export const refreshToken = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.AUTH.REFRESH_TOKENS_API, formData);
};