import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";

import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import AppRoutes from './routes';

import Loader from './components/common/Loader';

function App() {
    const [mounted, setMounted] = useState<boolean>(false);
    const theme = useSelector((state: any) => state.theme)

    /**
   * On load set app mounted and
   * load theme
   */
    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
    }, [mounted]);

    /**
     * Retrieves configurations and sets them in 
     * the redux store using dispatch.
     * @returns {void}
     */

    return (
        <React.Suspense fallback={
            <Loader isLoading={true} />
        }>
            <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
                <AppRoutes />
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover={false}
                    theme={theme.theme}
                />
            </BrowserRouter>
        </React.Suspense>
    );
}

export default App;
