
import { API_PATH } from '../constants/api-path';
import httpClient from './interceptor';

/**
 * 
 * @returns User details
 */
export const getAdminProfile = (): Promise<any> => {
    return httpClient.get(API_PATH.ADMIN.ADMIN_PROFILE);
};

/**
 * @param {formData: FormData } params
 * @returns Promise<any>
 */
export const updateAdminProfile = (formData: FormData): Promise<any> => {
    return httpClient.patch(API_PATH.ADMIN.UPDATE_PROFILE, formData);
};

/**
 * @param {formData: FormData } params
 * @returns Promise<any>
 */
export const changePassword = (formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.ADMIN.CHANGE_PASSWORD, formData);
};

export const getBrandsLists = (payloadData: any): Promise<any> => {
    return httpClient.post(API_PATH.BRAND.LISTING, payloadData);
};
/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const addBrand = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.BRAND.ADD, formData);
};

/**
 * @param {id: string formData: any } params
 * @returns Promise<any>
 */
export const updateBrand = (id: any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.BRAND.EDIT + id, formData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteBrand = (id: string | number): Promise<any> => {
    return httpClient.delete(API_PATH.BRAND.DELETE + id);
}

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const updateBrandStatus = (id:any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.BRAND.STATUS + id, formData);
};

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const updateStatusAPI = (module: string, id:any, formData: any): Promise<any> => {
    return httpClient.patch(`${API_PATH?.[module.toLocaleUpperCase()]?.STATUS}${id}`, formData);
};

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const restoreAPI = (module: string,id:any): Promise<any> => {
    return httpClient.patch(`${API_PATH?.[module.toLocaleUpperCase()]?.RESTORE}${id}`);
};

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const restoreBrand = (id:any): Promise<any> => {
    return httpClient.patch(API_PATH.BRAND.RESTORE + id);
};

export const getShippingTermLists = (payloadData: any): Promise<any> => {
    return httpClient.post(API_PATH.SHIPPING_TERM.LISTING, payloadData);
};
/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const addShippingTerm = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.SHIPPING_TERM.ADD, formData);
};

/**
 * @param {id: string formData: any } params
 * @returns Promise<any>
 */
export const updateShippingTerm = (id: any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.SHIPPING_TERM.EDIT + id, formData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteShippingTerm = (id: string | number): Promise<any> => {
    return httpClient.delete(API_PATH.SHIPPING_TERM.DELETE + id);
}

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const updateShippingTermStatus = (id:any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.SHIPPING_TERM.STATUS + id, formData);
};

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const restoreShippingTerm = (id:any): Promise<any> => {
    return httpClient.patch(API_PATH.SHIPPING_TERM.RESTORE + id);
};

export const getPaymentTermsLists = (payloadData: any): Promise<any> => {
    return httpClient.post(API_PATH.PAYMENT_TERM.LISTING, payloadData);
};
/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const addPaymentTerm = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.PAYMENT_TERM.ADD, formData);
};

/**
 * @param {id: string formData: any } params
 * @returns Promise<any>
 */
export const updatePaymentTerm = (id: any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.PAYMENT_TERM.EDIT + id, formData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deletePaymentTerm = (id: string | number): Promise<any> => {
    return httpClient.delete(API_PATH.PAYMENT_TERM.DELETE + id);
}

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const updatePaymentTermStatus = (id:any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.PAYMENT_TERM.STATUS + id, formData);
};

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const restorePaymentTerm = (id:any): Promise<any> => {
    return httpClient.patch(API_PATH.PAYMENT_TERM.RESTORE + id);
};

export const getAdminUsersList = (payloadData: any): Promise<any> => {
    return httpClient.post(API_PATH.ADMIN_USERS.LISTING, payloadData);
};

export const addAdminUser = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.ADMIN_USERS.ADD, formData);
};

export const updateAdminUser = (id:any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.ADMIN_USERS.EDIT + id, formData);
};

export const deleteAdminUser = (id: string | number): Promise<any> => {
    return httpClient.delete(API_PATH.ADMIN_USERS.DELETE + id);
}

/**
 * @param {formData: {status: string} } params
 * @returns Promise<any>
 */
export const updateAdminUserStatus = (id: string | number, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.ADMIN_USERS.STATUS + id, formData);
};

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const restoreAdminUser = (id:any): Promise<any> => {
    return httpClient.patch(API_PATH.ADMIN_USERS.RESTORE + id);
};

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const getAdminUserDetails = (id:any): Promise<any> => {
    return httpClient.get(API_PATH.ADMIN_USERS.DETAIL + id);
};

/**
 * @param {formData: FormData } params
 * @returns Promise<any>
 */
export const resetAdminPassword = (id:any, formData: any): Promise<any> => {
    return httpClient.patch(API_PATH.ADMIN_USERS.RESET_PASSWORD + id, formData);
};

export const getRolesLists = (payloadData: any): Promise<any> => {
    return httpClient.post(API_PATH.ROLES.LISTING, payloadData);
};

export const addRole = (formData: any): Promise<any> => {
    return httpClient.post(API_PATH.ROLES.ADD, formData);
};

export const updateRole = (formData: any, id:any): Promise<any> => {
    return httpClient.patch(API_PATH.ROLES.EDIT + id, formData);
};

export const deleteRole = (id: string | number): Promise<any> => {
    return httpClient.delete(API_PATH.ROLES.DELETE + id);
}

/**
 * @param {formData: any } params
 * @returns Promise<any>
 */
export const getRoleDetails = (id:any): Promise<any> => {
    return httpClient.get(API_PATH.ROLES.DETAIL + id);
};

export const getPermissionList = (): Promise<any> => {
    return httpClient.get(API_PATH.ROLES.PERMISSION_LIST);
};

export const getRolesList = (): Promise<any> => {
    return httpClient.get(API_PATH.ADMIN_USERS.ROLES_LIST);
};

export const validateToken = (token: any): Promise<any> => {
    return httpClient.post(API_PATH.AUTH.VALID_TOKEN, token);
};
