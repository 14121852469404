import { refreshToken } from "../services/auth.service";
import storage from "./storage";

/**
 * Extends the global `Window` interface to include the `grecaptcha` property.
 * 
 * @interface Window
 */
declare global {
    interface Window {
        grecaptcha: any;
    }
}

export const getToken = () => {

}

/**
 * 
 * @param {string} SITE_KEY
 * @returns {string} token
 */
export const getCaptchaToken = async (SITE_KEY: string): Promise<any> => {
    let token = null
    await window.grecaptcha?.execute(SITE_KEY, { action: "submit" })
        .then((res: string) => {
            token = res;
        })
    return token;
}

/**
 * Asynchronously refreshes the user's authentication token.
 *
 * @async
 * @function refreshTokenAPI
 * @returns {Promise<boolean>} A promise that resolves to true if the token is refreshed successfully, and false otherwise.
 *
 * @throws {Error} If there's an issue with the token refresh process.
 *
 * @example
 */
export const refreshTokenAPI = async () => {
    if (storage.getToken()) {
        const token = storage.getRefreshToken();
        if(token) {
            await refreshToken({ refresh_token: token }).then((response: any) => {
                storage.setToken(response?.data?.token);
                storage.setRefreshToken(response?.data?.refresh_token);
                return true;
            }).catch((error: any) => {
                return false;
            });
        }
    }
}

export const setApplicationModule = (path: string) => {
    if(path) {
        const prevModule = getApplicationModule();
        const module = path.split('/');
        let currentModule: string = '';
        if(['settings', 'user-management'].includes(module[2])) {
            currentModule = module[3];
        } else {
            currentModule = module[2];
        }
        
        if(prevModule !== currentModule) {
            storage.setData('currentModule', currentModule);
            storage.clearData('filters');
        }
    }
}

export const getApplicationModule = () => {
    return storage.getData('currentModule');
}

export const setFilter = (key: string, data: any) => {
    const filters = getAppliedFilters(key);
    
    const setFilters: any = {};
    if(filters) {
        setFilters[key] = {...filters, ...data}
    } else {
        setFilters[key] = {...data};
    }
    storage.setData('filters', setFilters);
}

export const getAppliedFilters = (key: any) => {
    const filters = storage.getData('filters');
    if(filters) {
        return filters[key];
    } else {
        return null;
    }
}

//Filter the Countries and set US and CA in top of the Dropdown
export const formatCountries = (countries: any[]) => {
    return countries
        ?.map(({ name, id, iso2, iso3 }) => ({ label: name, value: id, iso2, iso3 }))
        ?.sort((a, b) => {
            if (a.label === 'United States') return -1;
            if (b.label === 'United States') return 1;
            if (a.label === 'Canada') return -1;
            if (b.label === 'Canada') return 1;

            return a.label.localeCompare(b.label);
        });
};

export const capitalize = (data: string) => {
    return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

// Function to truncate text
export const truncateText = (text: string, maxLength: number): string => {
    return text?.length > maxLength ? text?.substring(0, maxLength) + "..." : text;
};

/**
 * Formats a cost value to ensure it displays correctly:
 * - If the value has two or fewer decimal places, it shows exactly two decimal places.
 * - If the value has more than two decimal places, it remains unchanged.
 * - Handles dollar sign formatting.
 *
 * @param {string} cost - The cost value to format (including the dollar sign).
 * @returns {string} - The formatted cost as a string.
 */
export const formatCost = (cost: string): string => {
    // Convert the cost to a string if it's not already a string
    if (typeof cost !== 'string') {
        cost = String(cost);
    }

    // Determine if the input has a dollar sign
    const hasDollarSign = cost.startsWith('$');

    // Remove the dollar sign and convert to a number
    const num = Number(cost.replace(/^\$/, ''));

    // Check if the conversion to number is successful
    if (isNaN(num)) {
        return '0.00'; // or return a default value like '$0.00'
    }

    // Convert the number back to string
    const costString = num.toString();

    // Check if the cost string has a decimal point
    if (costString.includes('.')) {
        // Split the string into whole and decimal parts
        const [wholePart, decimalPart] = costString.split('.');
        
        // Format the decimal part to a maximum of 7 digits
        const formattedDecimalPart = decimalPart.length > 7 ? decimalPart.slice(0, 7) : decimalPart;
        
        return `${hasDollarSign ? '$' : ''}${wholePart}.${formattedDecimalPart}`;
    } else {
        // If there's no decimal point, just format to two decimal places
        return `${hasDollarSign ? '$' : ''}${num.toFixed(2)}`;
    }
};
