import { API_PATH } from "../../constants/api-path";
import httpClient from "../../services/interceptor";


export const purchaseOrderService = {
	addPurchaseOrder: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.PURCHASE_ORDER.ADD, requestData);
	},

	editPurchaseOrder: (poId: any, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.PURCHASE_ORDER.EDIT + poId, payloadData);
	},

	getPurchaseOrderListing: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.PURCHASE_ORDER.LISTING, payloadData);
	},

	getUsersList: (): Promise<any> => {
		return httpClient.get(API_PATH.COMMON.BUYER_LIST);
	},

	getShippingTermsList: (): Promise<any> => {
		return httpClient.get(API_PATH.PURCHASE_ORDER.SHIPPING_TERMS_LIST);
	},

	getPaymentTermsList: (): Promise<any> => {
		return httpClient.get(API_PATH.PURCHASE_ORDER.PAYMENT_TERMS_LIST);
	},

	getVendorsList: (): Promise<any> => {
		return httpClient.get(API_PATH.COMMON.VENDOR_LIST);
	},

	getVendorList: (): Promise<any> => {
		return httpClient.get(API_PATH.PURCHASE_ORDER.VENDOR_LIST);
	},

	authorizePO: (requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.PURCHASE_ORDER.AUTHORIZE_PO, requestData);
	},

	manualConfirm: (requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.PURCHASE_ORDER.MANUAL_CONFIRM, requestData);
	},

	confirmPurchaseOrder: (queryParams: any): Promise<any> => {
		const params = `?t=${queryParams.t}`;

		return httpClient.get(API_PATH.PURCHASE_ORDER.CONFIRM_PURCHASE_ORDER + params);
	},

	resendPO: (requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.PURCHASE_ORDER.RESEND_PO, requestData);
	},

	reAuthorizePO: (requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.PURCHASE_ORDER.RE_AUTHORIZE_PO, requestData);
	},

	getPurchaseOrderDetail: (poId: string | number): Promise<any> => {
		return httpClient.get(API_PATH.PURCHASE_ORDER.DETAIL + poId)
	},

	receivePurchaseOrder: (poId: string | number, payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.PURCHASE_ORDER.RECEIVE_PO + poId, payloadData)
	},

	getVendotProductList: (vendorId: string | number): Promise<any> => {
		return httpClient.get(API_PATH.COMMON.VENDOR_PRODUCT_LIST + vendorId)
	},	

	getPurchaseOrderHisotry: (poId: number, queryParams: any): Promise<any> => {
		let query: any = queryParams.page ? `?page=${queryParams.page}` : '';
		query += `&row_per_page=${queryParams.row_per_page}`;
		
		return httpClient.get(API_PATH.PURCHASE_ORDER.HISTORY + poId + query);
	},

	voidPurchaseOrder: (requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.PURCHASE_ORDER.VOID, requestData);
	},

	editNotes: (poId: string | number, requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.PURCHASE_ORDER.EDIT_NOTES + poId, requestData);
	},

	exportPO: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.PURCHASE_ORDER.EXPORT, requestData);
	},
}