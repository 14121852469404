import { RouteObject } from "react-router-dom";

import ForgotPassword from "../pages/forgot-password";
import Login from "../pages/login";
import PageNotFound from "../pages/not-found";
import ResetPassword from "../pages/reset-password";
import ThankYou from "../pages/public-urls/thank-you";
import ConfirmPurchaseOrder from "../pages/public-urls/confirm-purchase-order";
import SalesChannelCallback from "../pages/settings/sales-channels/callback";
import PrintPerforma from "../pages/purchase-order-management/pdfs/printperforma";
import POPendingItems from "../pages/purchase-order-management/pdfs/poPendingItems";
import PrintPackagingSlip from "../pages/order-management/pdfs/printPackagingSlip";
import PrintPickListSlip from "../pages/order-management/pdfs/printPickListSlip";
import PrintShippingLabel from "../pages/order-management/pdfs/printShippingLabel";

/**
 * Public Routes Available in App
 * @type {{}}
 */

export const publicRoutes: RouteObject[] = [
    { path: '*', element: <PageNotFound /> },
    { path: 'forgot-password', element: <ForgotPassword /> },
    { path: 'login', element: <Login /> },
    { path: 'reset-password', element: <ResetPassword /> },
    { path: 'thank-you', element: <ThankYou /> },
    { path: 'confirm-purchase-order', element: <ConfirmPurchaseOrder /> },
    { path: 'print-proforma', element: <PrintPerforma /> },
    { path: 'po-pending-items', element: <POPendingItems /> },
    { path: 'apps/callback', element: <SalesChannelCallback /> },
    { path: 'print-packing-slip', element: <PrintPackagingSlip /> },
    { path: 'print-pick-list', element: <PrintPickListSlip /> },
    { path: 'print-shipping-label', element: <PrintShippingLabel /> },
];