import { Checkbox } from "primereact/checkbox";
import React from "react";
import { Form, Stack } from "react-bootstrap";

interface ICustomCheckbox {
    controlId?: string;
    value?: any;
    checked?: any;
    onChange?: any;
    disabled?: any;
    inputWrapClass?: any;
    wrapperClassname?: any;
    label?: any;
}

/**
 * Common Input Checkbox Field
 *
 * @param {{ controlId: any; value: any; checked: any; onChange: any; disabled: any; inputWrapClass: any; wrapperClassname: any; }} param0
 * @param {*} param0.controlId
 * @param {*} param0.value
 * @param {*} param0.checked
 * @param {*} param0.onChange
 * @param {*} param0.disabled
 * @param {*} param0.inputWrapClass
 * @param {*} param0.wrapperClassname
 * @returns {*}
 */
const CustomCheckbox: React.FC<ICustomCheckbox> = ({
    controlId,
    value,
    checked,
    onChange,
    disabled,
    inputWrapClass,
    wrapperClassname,
    label
}) => {
    return (
        <Form.Group className={wrapperClassname}>
            <Stack direction="horizontal" gap={2} className={inputWrapClass}>
                <Checkbox
                    inputId={controlId}
                    onChange={onChange}
                    checked={checked}
                    value={value}
                    name={controlId}
                    disabled={disabled}
                />
                <Form.Label
                    htmlFor={controlId}
                    className={`lh-sm mb-0 small ${disabled ? 'opacity-25 pe-none' : ''}`}
                >
                    {label}
                </Form.Label>
            </Stack>
        </Form.Group>
    );
};

export default CustomCheckbox;
